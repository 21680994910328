import React from 'react';
import { TicketPage } from '../../molecules/ticket';

const TicketPageWithId = ({ params }) => {
  const id = params.ticketId;

  return (
    <>
      <TicketPage id={id} />
    </>
  );
};

export default TicketPageWithId;
